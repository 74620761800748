<template>
  <div class="container" v-if="featureToggles.enableSessionSharing" :style="containerStyle">
    <div class="messages-area" ref="messagesArea">
      <ul>
        <li v-for="chat in state.chats" :key="chat.messageId"
          :class="chat.userId === profileId ? 'text-right' : 'text-left'"
          :style="chat.userId === profileId ? liRightStyle : liLeftStyle">
          <span v-if="chat.userId !== profileId">{{ chat.displayName }}: </span>
          <span v-else>me: </span>
          <span>{{ chat.message }}</span>
        </li>
      </ul>
    </div>
    <input v-model="state.message" :placeholder="tr('Start typing message…')" @keydown.enter="addMessage"
      :style="inputStyle" />
  </div>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js'

export default {
  props: {
    payload: {
      type: Object,
      required: true,
    },
    sessionId: {
      type: String,
      required: true,
    },
    sessionOwnerId: {
      type: String,
      required: true,
    },
    toolWidthInColumns: {
      type: Number,
      required: true,
    },
    toolHeightInRows: {
      type: Number,
      required: true,
    },
    toolId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()

    const containerStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
      }
    })

    const textStyle = computed(() => {
      return {
        color: store.getters.isLightMode ? 'black' : 'white',
      }
    })

    const liLeftStyle = computed(() => {
      return {
        border: `2px solid ${themeColors[store.getters.currentThemeName].buttonBorderColor}`,
        color: themeColors[store.getters.currentThemeName].modalTextColor,
        backgroundColor: themeColors[store.getters.currentThemeName].alternatingTableRowsBackgroundColor,
      }
    })

    const liRightStyle = computed(() => {
      return {
        border: `2px solid ${themeColors[store.getters.currentThemeName].buttonBorderColor}`,
        color: themeColors[store.getters.currentThemeName].baseHeaderTextColor,
        backgroundColor: themeColors[store.getters.currentThemeName].baseHeaderBackgroundColor,
      }
    })

    const inputStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
        color: themeColors[store.getters.currentThemeName].modalTextColor,
        borderColor: themeColors[store.getters.currentThemeName].toolBorderColor,
      }
    })

    const tr = inject('tr')

    const history = computed(() => props.payload.data.history || [])
    const state = reactive({
      chats: history.value,
      message: '',
    })
    watch(history, (chats) => {
      state.chats = chats
      setTimeout(scrollToLastMessage, 50)
    })

    onMounted(async () => {
      setTimeout(scrollToLastMessage, 50)
    })

    const profileId = computed(() => store.getters.userId)

    const messagesArea = ref(null)
    const scrollToLastMessage = () => {
      messagesArea.value.scrollTop = messagesArea.value.scrollHeight
    }
    const addMessage = () => {
      state.chats.push({ userId: profileId, displayName: store.getters.profile.displayName, message: state.message })
      state.message = ''
      setTimeout(scrollToLastMessage, 50)

      // Persist the update:
      const payload = {
        ...props.payload,
        ...{ data: { history: state.chats } },
      }
      const sessionOwnerId = props.sessionOwnerId
      const sessionId = props.sessionId
      const toolId = props.toolId
      const message = {
        payload,
        sessionOwnerId,
        sessionId,
        toolId,
        publish: true,
      }
      store.dispatch('updateToolPayload', message)
    }

    const featureToggles = computed(() => store.getters.featureToggles)

    return {
      addMessage,
      containerStyle,
      featureToggles,
      inputStyle,
      liLeftStyle,
      liRightStyle,
      messagesArea,
      profileId,
      state,
      textStyle,
      tr,
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  position: static;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.messages-area {
  width: 100%;
  flex: 2;
  overflow-y: scroll;
}

input {
  width: 100%;
  height: 2rem;
  border-width: 1px 0 0 0;
  border-style: solid;
  font-family: inherit;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 1px 1rem;
}

ul {
  margin: 5px;
  padding-left: 0;
}

li {
  list-style-type: none;
  margin: 8px 0px;
  padding: 13px 4px;
  border: 2px solid white;
  border-radius: 5px;
  width: 85%;
}

.text-left {
  text-align: left;
  background-color: goldenrod;
}

.text-right {
  margin-left: 15%;
  text-align: right;
  background-color: aliceblue;
}
</style>
